import React from 'react';

import SortIcon from '@assets/common/sort.png';
import ProfileImgPlaceholder from '@assets/comments/profileImgPlaceholder.png';
import UnFillHeartIcon from '@assets/comments/unFillHeartIcon.png';
import ShareIcon from '@assets/comments/shareIcon.png';
import ReplySection from '@components/ReplySection';

import numeral from 'numeral';
import moment from 'moment';

import BasicTableGrid from '@components/BasicTableGrid';
import PopUp from '@components/PopUp';
import EditPencilIcon from '@assets/common/editPencilIcon.png';
import CommentIcon from '@assets/common/commentsIcon.png';
import ShareIcon2 from '@assets/common/shareIcon.png';
import AnalyticsIcon from '@assets/common/analyticsIcon.png';
import CopyIcon from '@assets/common/copyIcon.png';
import TextAndLinks from '@components/TextAndLinks';

import User from "@components/User";


import './style.css';

function AllCommentsView(props) {
	return (
		<>
			<div
				className='rightHandRightPageMainContainer'
				style={{ maxWidth: '100%', 
						backgroundColor: '#ffffff', 
						display:'flex',
						flexDirection:'column',
						height: 'calc(100vh-58px)' 
						}}>
				
				<div className='marginLeftContainer'>
					<h4 className='heading'>Comments</h4>
				</div>

				{/* START : Filter */}

				{/* <hr style={{ marginBottom: '0px' }} />

				<div className='allComments_FilterContainer'>
					<img
						onClick={(event) => {
							props.handleSortContainerVisibility(event);
						}}
						className='sortIcon'
						src={SortIcon}
						alt='SortIcon'
					/>

					<div className='inputDateContainer'>
						<span>From</span>
						<br />
						<input
							onChange={(event) => {
								props.handleFromInputDate(event);
							}}
							value={props.fromInputDate}
							type='date'
						/>
					</div>

					<div className='inputDateContainer'>
						<span>To</span>
						<br />
						<input
							onChange={(event) => {
								props.handleToInputDate(event);
							}}
							value={props.toInputDate}
							type='date'
						/>
					</div>

					{props.sortErrorMessage.length > 0 && (
						<>
							<div
								className='alert-danger'
								style={{
									width: 'fit-content',
									padding: '5px 10px',
									border: '1px solid',
									borderRadius: '5px',
									marginTop: '20px',
								}}>
								<span>{props.sortErrorMessage}</span>
								<br />
							</div>
						</>
					)}

					{props.allCommentsSortContainerVisiable && (
						<div ref={props.allCommentsSortContainerRef} className='sortContainer'>
							<span
								onClick={(event) => {
									props.handleSortingByDate(event, 'today');
								}}>
								Today
							</span>
							<br />
							<span
								onClick={(event) => {
									props.handleSortingByDate(event, 'thisMonth');
								}}>
								This Month
							</span>
							<br />
							<span
								onClick={(event) => {
									props.handleSortingByDate(event, 'thisWeek');
								}}>
								This Week
							</span>
							<br />
							<span
								onClick={(event) => {
									props.handleSortingByDate(event, 'thisYear');
								}}>
								This Year
							</span>
						</div>
					)}
				</div> */}
				{/* END : Filter */}

				<hr />

				<div className='videoPage_CommentsPage_FilterContainer'>
					<button
						id='allFilters'
						onClick={(event) => {
							props.handleFilter(event, 'all');
						}}
						className={`filterBtns ${props.selectedFilter === 'all' ? 'selectedFilter' : ''
							}`}>
						All Comments
					</button>
					<button
						id='publishedFilter'
						onClick={(event) => {
							props.handleFilter(event, 'published');
						}}
						className={`filterBtns ${props.selectedFilter === 'published' ? 'selectedFilter' : ''
							}`}>
						Published
					</button>

					<button
						id='holdForReviewFilter'
						onClick={(event) => {
							props.handleFilter(event, 'heldForReview');
						}}
						className={`filterBtns ${props.selectedFilter === 'heldForReview' ? 'selectedFilter' : ''
							}`}>
						Held for review
					</button>

					<button
						id='rejectedFilter'
						onClick={(event) => {
							props.handleFilter(event, 'rejected');
						}}
						className={`filterBtns ${props.selectedFilter === 'rejected' ? 'selectedFilter' : ''
							}`}>
						Rejected
					</button>
				</div>

			    <BasicTableGrid 
				gridId='comments'
				data={props.deferedData} // Data to be displayed
				initialSize = {[1008,449]}
				fixedCols={2} // How many columns are fixed and not scrolling
				handleSelect={(selectedRows) => {
					// props.handleCheckBoxes(selectedRows);
				}}
				length={20}
				pagination={props.pagination}
				setPagination={props.setPagination}
				onSortChange={(data) => {
					// props.handleSort(data)
				}
				}
				columnsOptionPopUpRef={props.columnsOptionPopUpRef}
				isCheckBoxVisible={false}
				columns={[
				{
				 id:'Comments',
					cell: (data) => {
					const commentData = data.row.original;
					return (<div className='allCommentsContainer ps-2 w-100 d-flex'>
									<div className='col-profileImgPlaceholder'>
										{/* <img
											src={commentData.displayPhoto
													? commentData.displayPhoto
													: ProfileImgPlaceholder}
											// alt='Profile Placeholder'
											style={{ width: '30px' }}
										/> */}
										<User
											imageText={commentData.displayName ? commentData.displayName : commentData.username}
											image={commentData?.displayPhoto}
											size={"40px"}
											fontSize={"15px"}
										/>
									</div>

									<div className='col-commentContainer' style={{ paddingLeft: '20px' }}>
										<p className='commenterName'>
											@{commentData.displayName ? commentData.displayName : commentData.username}
											<span className='commentTime'>
												{moment(commentData.createdAt).fromNow()}
											</span>
										</p>
										<p className='comment mt-2'>
											<div dangerouslySetInnerHTML={{ __html: commentData.comment }} />
										</p>
										<div className='commentInteractionContainer'>
											<button
												className='commentReplyBtn'
												data-reply_container_id={commentData._id}
												data-has_reply_container={'has_' + commentData._id}
												onClick={(event) =>
													props.handleCommentReply(event, commentData._id, 'replyOnComment')
												}>
												Reply
											</button>

											{commentData.replies?.length > 0 && (
												<button
													className='accordion-button collapsed'
													type='button'
													data-bs-toggle='collapse'
													data-bs-target={'#commentRepliesCollapse_' + commentData._id}>
													{commentData.replies.length}{' '}
													{commentData.replies.length > 1 ? 'Replies' : 'Reply'}
												</button>
											)}

											<p
												className='commentLikeContainer'
												onClick={() => props.handleLikeComment(commentData)}>
												{commentData.isLiked ? (
													<i className='fa-solid fa-thumbs-up'></i>
												) : (
													<i className='fa-regular fa-thumbs-up'></i>
												)}
												<span>{numeral(commentData.likesCount).format('0.0a')}</span>
											</p>

											<p
												className='commentDislikeContainer'
												onClick={() => props.handleDislikeComment(commentData)}>
												{commentData.isDisliked ? (
													<i className='fa-solid fa-thumbs-down fa-flip-horizontal'></i>
												) : (
													<i className='fa-regular fa-thumbs-down fa-flip-horizontal'></i>
												)}
											</p>

											{/* <img
												className='favIcon'
												src={UnFillHeartIcon}
												alt='UnFillHeartIcon'
											/> */}

											<img className='shareIcon' src={ShareIcon} alt='ShareIcon' />
										</div>

										<ReplySection
											currentCommentId={commentData._id}
											commentRepliesData={commentData.replies}
											handleReplyStatus={props.handleReplyStatus}
											handleReplyLike={(reply) =>
												props.handleReplyLike(reply, commentData.videoDocId)
											}
											handleReplyDislike={(reply) =>
												props.handleReplyDislike(reply, commentData.videoDocId)
											}
											handleCommentReply={props.handleCommentReply}
										/>
									</div>

									<div className='col-commentActionContainer'>
										{(commentData.status === 'published' ||
											commentData.status === 'rejected') && (
												<button
													onClick={(event) => {
														props.commentAction('heldForReview', commentData._id);
													}}
													className='commentActionBtn'>
													Held for review
												</button>
											)}

										{commentData.status === 'heldForReview' && (
											<button
												onClick={(event) => {
													props.commentAction('published', commentData._id);
												}}
												className='commentActionBtn'>
												Approve
											</button>
										)}

										{(commentData.status === 'heldForReview' ||
											commentData.status === 'published') && (
												<button
													onClick={(event) => {
														props.commentAction('rejected', commentData._id);
													}}
													className='commentActionBtn'
													style={{ marginLeft: '15px' }}>
													Reject
												</button>
											)}
									</div>
							</div>);
					},
				},
				{id: 'Course', 
				cell : (data)=>{
					
					const commentData = data.row.original;
					return (<TextAndLinks
							title= {commentData.videoTitle}
							subTitle= {'This is subtitle. Course name will come here'}
							images = {[EditPencilIcon, CommentIcon, AnalyticsIcon, ShareIcon2, CopyIcon]}
					        onClick={(event, index)=>{props.handleRedirectToVideo(index, commentData.videoDocId)}}
							/>)
				}},
				
				]}
				/>
				
			</div>
		</>
	);
}

export default AllCommentsView;
