import React from "react";
import { Link } from "react-router-dom";

import "./style.css";

import TextInputV2 from "@components/TextInputV2";

import MoveBarIcon from "@assets/common/moveBarIcon.png";
import PlusCircleIcon from "@assets/common/plusCircleIcon.png";
import UploadIcon from "@assets/common/uploadIcon.png";
import DeleteIcon from "@assets/common/deleteIcon.png";

import { DragDropContext, Droppable } from "react-beautiful-dnd";
//DnD Import
import DnD from "@components/DnD";

import PopUp from "@components/PopUp";
import ImageInput from "@components/ImageInput";
import SelectSeriesPopUp from "@components/Courses/SelectSeriesPopUp";
import SaveBtn from "@components/SaveBtn";

function ContentView(props) {
  return (
    <div
      className="rightHandRightPageMainContainer flex-grow-1 gap-3 d-flex flex-column"
      style={{ maxWidth: "none" }}
    >
      <div className="topContainer">
        <h4 className="heading">Video Content</h4>
        <div>
          <Link
            style={{ textDecoration: "none" }}
            className="cancelBtn"
            to="/content?tab=courses"
          >
            Cancel
          </Link>
          <SaveBtn
            onClick={props.handleSave}
            text={"Save"}
            altText={"Saving..."}
            disabled={props.isFormSaving}
          />
        </div>
      </div>
      <div
        className="d-flex gap-3 flex-grow-1"
        style={{
          maxWidth: "none",
          borderTop: "1px solid #e5e5e5",
          overflow: "scroll",
        }}
      >
        <div
          className="d-flex ms-3"
          style={{ width: "100%", maxWidth: "729px", paddingBottom: "10%" }}
        >
          <div className="marginLeftContainer w-100 d-flex gap-3 flex-column mt-5 pt-2">
            {/* START : Error container */}
            {props.formValuesEmpty && (
              <div className="incompleteFieldsError">
                <span>All input fields are required</span>
              </div>
            )}
            {/* END : Error container */}
            {/* START : Deal ending section */}
            <div style={{ paddingBottom: "10%" }}>
              <DragDropContext onDragEnd={props.onDragEnd}>
                <Droppable droppableId="SeriesSet">
                  {(provided) => (
                    <div
                      className="d-flex flex-column gap-4"
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      {props.courseContent?.map((series, index) => (
                        <DnD
                          key={index}
                          element={series}
                          index={index}
                          newComponent={
                            <div
                              key={index}
                              className="d-flex gap-2 align-items-center"
                              style={{ fontSize: "14px" }}
                            >
                              <div
                                className="review"
                                style={{ height: "auto" }}
                              >
                                <div>
                                  <img
                                    className="moveIcon"
                                    src={MoveBarIcon}
                                    style={{ height: "5px" }}
                                  ></img>
                                </div>
                                <div className="reviewContent gap-2">
                                  <div className="reviewIndex">
                                    {"Series " + (index + 1)}
                                  </div>
                                  <div className="d-flex align-items-center seriesEdit gap-2">
                                    <div
                                      className="SeriesThumbnail"
                                      style={{
                                        backgroundImage: series.thumbnailUrl
                                          ? `url(${series.thumbnailUrl})`
                                          : `url(${UploadIcon})`,
                                        width: "80px",
                                        height: "80px",
                                      }}
                                      onClick={(event) =>
                                        props.handleOpenPopUp(
                                          event,
                                          "imageUpload",
                                          index
                                        )
                                      }
                                    >
                                      <img src={UploadIcon} />
                                    </div>
                                    <TextInputV2
                                      placeholder={"Display Series name"}
                                      value={series.title}
                                      required={true}
                                      onChange={(event) => {
                                        props.handleChange(
                                          event.target.value,
                                          "title",
                                          index
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          }
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <center>
                {
                  <div className="bg-light w-100 p-3 mt-2">
                    <button
                      className="btn btn-dark d-flex gap-2 align-items-center w-50 justify-content-center"
                      style={{ fontSize: "14px" }}
                      onClick={(event) => {
                        props.handleOpenPopUp(event, "seriesSelect", null);
                      }}
                    >
                      <img className="addReviewIcon " src={PlusCircleIcon} />
                      Add content
                    </button>
                  </div>
                }
              </center>
            </div>
          </div>
        </div>
      </div>

      <PopUp
        visible={props.isSelectSeriesPopUpVisible}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div className="full-page-overlay">
          <SelectSeriesPopUp
            searchPlaceholder={"Search for series"}
            selectedSeries={props.courseContent}
            allSeries={props.allSeries}
            handleClose={props.handlePopUpClose}
            handleSave={(event) => props.handleChange(event, "series")}
          />
        </div>
      </PopUp>
      <PopUp
        visible={props.isImageUploadPopUpVisible}
        top={props.popUpPosition.top}
        left={props.popUpPosition.left}
        handleOutSideClick={props.handlePopUpClose}
      >
        <div className="full-page-overlay">
          <ImageInput
            handleClose={props.handlePopUpClose}
            handleAdd={(event, files) =>
              props.handleChange(files, "imageUpload")
            }
          />
        </div>
      </PopUp>
    </div>
  );
}

export default ContentView;
