import React from "react";
import {
  Page,
  Document,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

import InterRegular from "@assets/invoice/Inter-Regular.ttf";
import InterMedium from "@assets/invoice/Inter-Medium.ttf";
import InterSemiBold from "@assets/invoice/Inter-SemiBold.ttf";
import InterBold from "@assets/invoice/Inter-Bold.ttf";

Font.register({ family: "InterRegular", src: InterRegular });
Font.register({ family: "InterMedium", src: InterMedium });
Font.register({ family: "InterSemiBold", src: InterSemiBold });
Font.register({ family: "InterBold", src: InterBold });

const styles = StyleSheet.create({
  lightGrayFColor: {
    color: "#7f7f7f",
  },

  tableSection: {
    marginTop: 10,
    marginLeft: -25,
    padding: 0,
    width: "525px",
  },

  tHead: {
    marginTop: 33,
    flexDirection: "row",
    backgroundColor: "#eef2f3",
    paddingTop: 8,
    paddingBottom: 5,
    paddingLeft: 4,
  },

  col1: {
    fontFamily: "InterSemiBold",
  },

  col2: {
    marginLeft: 10,
    fontFamily: "InterSemiBold",
  },

  col3: {
    marginLeft: 321,
    fontFamily: "InterSemiBold",
  },

  tBody: {
    marginLeft: 12,
  },

  tBodyRow: {
    flexDirection: "row",
    marginTop: 15,
  },

  srNo: {
    width: "20px",
  },

  productName: {
    marginLeft: 10,
    width: "380px",
  },

  price: {
    marginLeft: 8,
  },

  tBody2: {
    marginTop: 20,
    marginLeft: 280,
    fontSize: 10,
  },

  tBody2Row: {
    flexDirection: "row",
  },

  tBody2RowTitle: {
    width: "100px",
    textAlign: "right",
    marginTop: 5,
  },

  tBody2RowValue: {
    marginLeft: 50,
    marginTop: 5,
  },

  tBody2RowGST_Text: {
    marginTop: 10,
    width: "100px",
    textAlign: "right",
    fontSize: 7,
    color: "#7f7f7f",
  },

  tBody2RowGST_Title: {
    width: "100px",
    textAlign: "right",
    marginTop: 1,
    color: "#7f7f7f",
  },

  tBody2RowGST_Value: {
    marginLeft: 50,
    marginTop: 1,
    color: "#7f7f7f",
  },
});

const InvoiceTable = ({ invoice }) => (
  <>
    <View style={styles.tableSection}>
      <View style={styles.tHead}>
        <Text style={styles.col1}>Sr.No.</Text>
        <Text style={styles.col2}>Product Name</Text>
        <Text style={styles.col3}>Total</Text>
      </View>

      <View style={styles.tBody}>
        {invoice.data.courses &&
          invoice.data.courses.map((course, courseIndex) => (
            <>
              <View style={styles.tBodyRow}>
                <Text style={styles.srNo}>{courseIndex + 1}</Text>
                <Text style={styles.productName}>{course.title}</Text>
                <Text style={styles.price}>{course.offerPrice}</Text>
              </View>
            </>
          ))}
      </View>

      <View style={styles.tBody2}>
        {invoice.data.couponDetails && invoice.data.couponDetails != null && (
          <View style={styles.tBody2Row}>
            <Text style={styles.tBody2RowTitle}>Coupon Code</Text>
            <Text style={styles.tBody2RowValue}>couponCode</Text>
          </View>
        )}

        {invoice.data.discount && invoice.data.discount > 0 && (
          <View style={styles.tBody2Row}>
            <Text style={styles.tBody2RowTitle}>Discount</Text>
            <Text style={styles.tBody2RowValue}>-{invoice.data.discount}</Text>
          </View>
        )}

        <View style={styles.tBody2Row}>
          <Text style={styles.tBody2RowTitle}>
            <Text style={{ fontFamily: "InterBold" }}>Total Amount</Text>
          </Text>
          <Text style={styles.tBody2RowValue}>
            <Text style={{ fontFamily: "InterBold" }}>
              {invoice.data.currencySymbol} {invoice.data.netTotal}
            </Text>
          </Text>
        </View>

        {/* <View style={styles.tBody2Row}>
					<Text style={styles.tBody2RowGST_Text}>(Tax inclusive, rounded off)</Text>
				</View>

				<View style={styles.tBody2Row}>
					<Text style={styles.tBody2RowGST_Title}>CGST 9%</Text>
					<Text style={styles.tBody2RowGST_Value}>
						{invoice.data.currencySymbol}{' '}
						{(invoice.data.netTotal / 118) * 100 * (9 / 100)}
					</Text>
				</View>
				<View style={styles.tBody2Row}>
					<Text style={styles.tBody2RowGST_Title}>SGST 9%</Text>
					<Text style={styles.tBody2RowGST_Value}>
						{invoice.data.currencySymbol}{' '}
						{(invoice.data.netTotal / 118) * 100 * (9 / 100)}
					</Text>
				</View> */}
      </View>
    </View>
  </>
);

export default InvoiceTable;
