import React from 'react';

import './style.css';

function DragDropFileInputView(props) {

    return (
        <div>
            <input type='button'
                value="Upload from computer"
                onClick={props.handleUploadClick}
            ></input>
            <input
                type='file'
                ref={props.fileInputEle}
                className='d-none'
                accept='image/jpeg,image/jpg,image/png,image/svg+xml'
                multiple
            />
        </div>
    )

    // return (
    //     <div class="dragInputDiv"
    //         onDrop={props.dropHandler}
    //         onDragOver={props.dragOverHandler}>
    //         <div>Drag & drop files here</div>
    //         <div>or</div>
    //         <input type='button'
    //             value="Upload from computer"
    //             onClick={props.handleUploadClick}
    //         ></input>
    //         <input
    //             type='file'
    //             className='d-none'
    //             accept='image/jpeg,image/jpg,image/pngs,image/svg+xml'
    //             multiple
    //         />
    //     </div>
    // )
}

export default DragDropFileInputView