// Import the AWS SDK
const AWS = require("aws-sdk");
const { S3Client, ListObjectsV2Command } = require("@aws-sdk/client-s3");

export const getImagesFromAwsBucket = async (
  folderName = null,
  searchValue = ""
) => {
  try {
    const BASE_URL = process.env.REACT_APP_AWS_BASE_URL;

    // S3 Client Initialization
    const s3Client = new S3Client({
      region: process.env.REACT_APP_AWS_REGION,
      credentials: {
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
      },
    });

    const command = new ListObjectsV2Command({
      Bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
      Prefix: folderName ? folderName + "/" : "",
    });
    const response = await s3Client.send(command);

    let listOfImages = [];
    if (searchValue !== "") {
      listOfImages = response.Contents.map((img) => {
        return BASE_URL + img.Key;
      });
    } else {
      listOfImages = response.Contents.map((img) => {
        return BASE_URL + img.Key;
      });
    }

    return {
      success: true,
      listOfImages: listOfImages,
    };
  } catch (error) {
    console.error("Error fetching images from AWS S3:", error);
    return {
      success: false,
      message: error,
    };
  }
};
